@import url(antd/dist/antd.css);
.date .react-datepicker-wrapper {
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.show {
  display: block !important;
}

.hide {
  display: none !important;
}

.toast {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 2000;
}

.svg-inline--fa.fa-w-11 {
  width: 0.6875em;
}

.modal .modal-header {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.modal .modal-header button.close {
  background: none;
}

.modal-content .modal-header .close svg,
.modal-content .modal-body .close svg,
.modal-content .modal-footer .close svg {
  color: #02a3af;
  font-size: 20px;
}

.p-medical_management .iprup .btn-success {
  width: 60px;
}

.p-medical_management .iprup input.form-control {
  width: calc(100% - 60px);
}

.p-medical_management .iprup {
  width: 100%;
}

.w-200-px {
  width: 200px;
}

.p-login {
  height: calc(100vh);
}

.dataTable-table > thead > tr > td {
  background: #f3f1f1;
  vertical-align: middle;
  text-align: center;
}

.w-1-rem {
  width: 1rem;
}

.text-error {
  margin-top: 1rem;
  margin-bottom: 0px;
  color: red;
}

.img-wrapper figure {
  -o-object-fit: cover;
  object-fit: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.img-wrapper label {
  margin-top: auto;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.loading-center {
  width: 50%;
  margin: 0 auto;
}

.animate-item {
  -webkit-animation: fadein 1s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s;
  /* Firefox < 16 */
  -ms-animation: fadein 1s;
  /* Internet Explorer */
  -o-animation: fadein 1s;
  /* Opera < 12.1 */
  animation: fadein 1s;
}

.btn-group-end button {
  border-radius: 3px !important;
  padding: 0.075rem 0.75rem;
  font-size: 14px;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media all and (min-width: 1200px) {
  .p-medical_management .teeth-wrapper svg g.hover path:first-child {
    fill: #aaa !important;
  }
  .p-medical_management .teeth-wrapper svg g.selected path:first-child {
    fill: #f34848 !important;
  }
}

.p-otp .login-bottom,
.p-forgetpass .login-bottom,
.p-changepass .login-bottom {
  height: 918px;
}

.c-pointer {
  cursor: pointer;
}

.small-button svg {
  height: 20px;
}

.small-button .loading-center {
  height: initial !important;
}

.p-medical_management .table-detailbox th {
  min-width: 70px;
}

.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.vertical-initial {
  vertical-align: initial !important;
}

.background-blue {
  background-color: #e6f8f8 !important;
}

.table_detail > tbody > tr > td.vertical-initial {
  vertical-align: top !important;
  padding: 20px !important;
}

.analytic-date-wrapper {
  width: 100% !important;
}

body {
  line-height: 1.5715 !important;
}

.align-center {
  text-align: center;
}

.h300 {
  height: 300px !important;
}

.linewrap {
  white-space: pre-line;
}

.cell-red-border {
  position: relative;
}

.cell-red-border span {
  z-index: 2;
  position: relative;
}

.cell-red-border::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  display: block;
  z-index: 1;
  border: 2px solid red;
}

.btn-center {
  text-align: center;
  border: none !important;
}

.select__value-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.select__value-container .select__input-container {
  display: none;
}

.loss {
  background: #ececec !important;
  opacity: 0.7;
}

.border-box-card {
  border: 3px solid #02a3af;
  border-radius: 15px;
  padding: 3px 15px;
}

.planchange-home .card-body .text_underline {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: inline-block;
  width: 200px !important;
  border: 1px solid #02a3af;
  padding: 10px 20px;
  border-radius: 10px;
  text-align: center;
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

@media all and (min-width: 1280px) {
  .planchange-home .card-body .text_underline:hover {
    background: #02a3af;
    color: #ffffff;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }
}

body iframe {
  display: none;
}

.horizontal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* justify-content: space-between; */
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  min-width: 248px;
}

.comment {
  width: calc(100% - 258px);
}

.doctor-comment {
  height: auto !important;
}

.client-name {
  font-weight: bold;
  color: #3fb409;
}

.remember-text {
  color: gray;
  font-weight: normal !important;
}

.selected {
  background: #e6f8f8 !important;
  color: #000000 !important;
}

.p-planchange .plan-body .selected .plan-name {
  color: black;
}

.scrollable {
  max-height: 600px;
  overflow-y: auto;
}

.btn-white {
  background: white !important;
  color: #02a3af !important;
  border: 1px solid #02a3af !important;
  border-radius: 8px !important;
  -webkit-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.btn-white:hover {
  background: #02a3af !important;
  color: white !important;
}

.p-medical_management .list-galerry figure img {
  height: 100%;
}

.model-3d {
  margin: 0 auto;
  height: 350px;
}

.upload_wrapper {
  background: #f2f5f5;
  padding: 30px 95px;
  border-radius: 10px;
}

.upload_wrapper .upload_wrapper_ins {
  border: 1px solid #c7c7c9;
  padding: 25px;
  border-radius: 10px;
}

.upload_wrapper .upload_wrapper_ins span {
  display: block;
  text-align: center;
  color: #c7c7c9;
  font-weight: bold;
}

.upload_wrapper .upload_wrapper_ins figure {
  text-align: center;
  height: initial;
}

.upload_wrapper .upload_wrapper_ins img {
  margin: 0 auto;
  height: 54px !important;
}

.img-wrapper-custom {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 33% calc(100% - 33%);
  grid-template-columns: 33% calc(100% - 33%);
  flex-wrap: wrap;
}

.img-wrapper-custom2 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 66% calc(100% - 66%);
  grid-template-columns: 66% calc(100% - 66%);
}

.overflow {
  white-space: nowrap;
  /* width: 50px; */
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.grid-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 25% 25% 25% 25%;
  grid-template-columns: 25% 25% 25% 25%;
  background-color: transparent;
  padding: 30px 0px 10px 0px;
}

.grid-item {
  background-color: #f2f5f5;
  border-radius: 10px;
  padding: 20px;
  font-size: 16px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 10px;
}

.fs16 {
  font-size: 16px;
}

.fs14 {
  font-size: 14px;
}

.p-ply-download .grid-item .file_name {
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  text-align: center;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
}

.p-ply-download .grid-item .datetime {
  color: #818181;
}

.p-ply-download .grid-item img {
  width: 50%;
}

.p-ply-download .download-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #02a3af;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 10px;
  border: #02a3af solid 2px;
  border-radius: 8px;
  cursor: pointer;
}

.p-ply-download .download-btn:hover {
  opacity: 0.5;
}

.p-ply-download .download-btn img {
  height: 40px;
  width: 40px;
}

.p-ply-download .download-btn span {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.p-ply-download .grid-item .grid-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.p-ply-download .grid-item .grid-head .head-item {
  width: 30px;
  height: 30px;
}

.p-ply-download .grid-item .close-btn:hover {
  opacity: 0.5;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.flex-H {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex-H > * {
  margin: 0 10px 0 0;
}

.enlarge {
  right: 0;
  bottom: 0;
}

.shrink {
  -ms-flex-preferred-size: auto !important;
  flex-basis: auto !important;
}

.nav-shrink {
  padding: 10px !important;
}

.enlarge-shrink {
  margin-top: 10px;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.modal-patient {
  max-width: 35% !important;
}

.modal-patient > *,
.modal-patient .modal-content > * {
  border: none !important;
}

.modal-patient .modal-content .modal-body {
  padding: 0 30px;
}

.modal-patient .p {
  padding: 20px 40px;
}

.modal-content .patient-input {
  border: 2px solid;
  border-radius: 5px 5px;
}

.modal-content .patient-list {
  overflow-y: scroll;
  height: 400px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.modal-content .patient-list::-webkit-scrollbar {
  width: 7px;
}

.modal-content .patient-list::-webkit-scrollbar-track {
  background-color: #f4f4f4;
  border-radius: 10px;
}

.modal-content .patient-list::-webkit-scrollbar-thumb {
  background-color: #dbdbdb;
  border-radius: 10px;
}

.modal-content .patient-list .item {
  height: 50px;
  margin: 5px 5px;
  border-radius: 10px;
  background-color: #ebebeb;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 20px;
  cursor: pointer;
}

.modal-content .patient-list .item:hover {
  opacity: 0.6;
}

.modal-content .patient-list .item.active {
  background-color: #02a3af;
}

.modal-content .patient-list .item span {
  color: #c6c6c6;
  font-weight: 300;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.modal-content .patient-list .item.active span {
  color: white;
  font-weight: 500;
}

.modal-content .modal-patient-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.modal-content .btn-link-patient {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  min-width: 140px;
}

.modal-content .patient-list .btn-loadmore {
  border: 2px solid #02a3af !important;
  background-color: transparent !important;
  min-width: 0px;
  color: #02a3af;
  padding: 5px;
  border-radius: 10px;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.head-item.hide {
  display: block !important;
  opacity: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.no-interaction {
  display: block !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.gray-btn {
  background-color: grey !important;
  border-color: grey !important;
}

.color-placeholder {
  width: 30px;
  height: 30px;
  border-radius: 4px;
}

.pop-color {
  z-index: 1000;
  position: absolute;
  background-color: white;
  background: #ffffff;
  -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 10px;
}

.pop-color > * {
  margin: 5px;
  cursor: pointer;
}

.color-picker .grid-container {
  -ms-grid-columns: 20% 20% 20% 20% 20%;
  grid-template-columns: 20% 20% 20% 20% 20%;
}

.color-picker .grid-container .actived {
  border: #02a3af solid 2px;
}

.color-picker .grid-container .color-placeholder:hover {
  opacity: 0.4;
}

.color-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.max {
  width: 94vw;
  height: 94vh;
  max-width: 100%;
  max-height: 100%;
}

model-viewer {
  min-height: 80vh;
  max-height: 85vh;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

model-viewer canvas {
  width: 70vh !important;
  height: 100vh !important;
}

model-viewer:host {
  width: 350px;
  height: 250px;
}

model-viewer .container {
  width: 70vh !important;
  height: 100vh !important;
}

#model-viewer {
  position: relative;
}
.model-viewer-progress-bar {
  width: 100%;
  height: 5px;
  background-color: grey;
  position: absolute;
}
.model-viewer-progress-bar .progress {
  height: 5px;
}

.confirm-delete {
  display: inline-block;
  max-width: initial !important;
}

.confirm-delete .modal-content {
  border-radius: 10px;
  border: none;
  padding: 5px 10px 20px 10px;
}

.confirm-delete .modal-content > * {
  margin: 10px;
}

.confirm-delete .modal-header,
.confirm-delete .modal-body,
.confirm-delete .modal-footer {
  border: none;
  padding: 0;
}

.confirm-delete .modal-body {
  text-align: center;
  margin-left: 30px;
  margin-right: 30px;
}

.confirm-delete .modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 10px;
}

.confirm-delete .modal-footer > * {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.flexible {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.flexible.show {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal.fade.show {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.no-data {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 14px;
}

._loading_overlay_overlay {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10000 !important;
}

.header-image {
  cursor: pointer;
}

.p-morthodontist .container-fluid .dataTable-table thead tr td:first-child {
  white-space: nowrap;
}

.loading-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10000;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.5);
}

.loading-overlay.show {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  position: fixed;
}

.loading-overlay .loader {
  background-color: white;
  padding: 5px;
  opacity: 1;
  border-radius: 1000px;
  display: inline-block;
  -webkit-animation-name: ckw;
  animation-name: ckw;
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@-webkit-keyframes ckw {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  12.5% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  25% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  37.5% {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  62.5% {
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg);
  }
  75% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  87.5% {
    -webkit-transform: rotate(315deg);
    transform: rotate(315deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes ckw {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  12.5% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  25% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  37.5% {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  62.5% {
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg);
  }
  75% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  87.5% {
    -webkit-transform: rotate(315deg);
    transform: rotate(315deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-right: 20px;
}

.hamburger {
  width: 44px;
  height: 44px;
  background: #02a3af;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  display: none;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

@media all and (max-width: 1200px) {
  .hamburger {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.hamburger span {
  left: 50%;
  height: 2px;
  position: absolute;
  background: #fff;
  display: inline-block;
  border-radius: 3px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  width: 50%;
  margin-left: -25%;
}

.hamburger span:first-child {
  top: 27%;
}

.hamburger span:nth-child(2) {
  top: auto;
  bottom: auto;
}

.hamburger span:last-child {
  bottom: 27%;
}

.hamburger.active span:nth-child(1) {
  -webkit-transform: translateY(9px) rotate(-225deg);
  -ms-transform: translateY(9px) rotate(-225deg);
  transform: translateY(9px) rotate(-225deg);
}

.hamburger.active span:nth-child(2) {
  -webkit-transform: translateY(0) rotate(225deg);
  -ms-transform: translateY(0) rotate(225deg);
  transform: translateY(0) rotate(225deg);
}

.hamburger.active span:nth-child(3) {
  opacity: 0;
}

.horizontal .comment {
  width: calc(100% - 200px);
}

.p-medical_management .dataTable-container-scroll.fixscroll .dataTable-table {
  width: 100%;
}

@media (max-width: 1199px) {
  #layoutSidenav #layoutSidenav_nav .sb-sidenav-light {
    background: none;
  }
  #layoutSidenav #layoutSidenav_nav .enlarge-shrink {
    display: none;
  }
  #layoutSidenav #layoutSidenav_nav.show {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  #layoutSidenav #layoutSidenav_content {
    min-height: calc(100vh - 100px);
  }
  .grid-item {
    padding: 10px;
  }
  .p-ply-download .grid-item .grid-head .head-item {
    width: 24px;
    height: 24px;
  }
  .form-control,
  .page-link,
  .input-group .btn {
    font-size: 14px !important;
  }
  .btn {
    min-height: 28px;
  }
  .table_detail > tbody > tr > td {
    padding: 10px 0 !important;
  }
  .p-medical_management .table {
    table-layout: fixed;
  }
  .p-medical_management .table_detail > tbody > tr > td.vertical-initial {
    padding: 10px !important;
  }
  .delete-btn {
    width: 24px;
    height: 24px;
  }
  .modal-patient {
    max-width: 80% !important;
  }
  .modal-content .patient-list .btn-loadmore {
    min-height: inherit;
  }
  .p-medical_management .modal-dialog .table-detailbox {
    table-layout: auto;
  }
}

.blockPdf {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.blockPdf .btn {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blockPdf .comment {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: auto;
}

.nav-link {
  position: relative;
}

.nav-link .number {
  position: absolute;
  color: #fff;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  right: 10px;
  top: 50%;
  margin-top: -10px;
  background: #f34848;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 12px;
}

.color-picker .grid-container {
  -ms-grid-columns: 25% 25% 25% 25%;
  grid-template-columns: 25% 25% 25% 25%;
}

.text-valid {
  color: #02a3af;
}

.text-invalid {
  color: #f34848;
}

.dataTable-container-patient .tdemail {
  min-width: 220px;
}

.dataTable-container-patient .select-style {
  width: inherit;
  min-width: 120px;
}

.dataTable-container-patient .select-style.select-styleStatus {
  min-width: 100px;
}

.dataTable-container-patient .select-style.select-styleStatus select {
  padding-left: 10px;
}

.dataTable-container-patient .dataTable-table td .fcc a.btn-fixwidth {
  width: auto;
}

.p-medical_management .dataTable-container-scroll {
  padding-bottom: 0;
  overflow-x: auto;
}

.dataTable-container-scroll {
  min-height: 350px;
}

.p-medical_management .dataTable-container-scroll .dataTable-table {
  width: auto;
  min-width: 100%;
}

.p-medical_management .datepick .date {
  width: 125px;
}

.p-medical_management .datepick .date .form-control {
  padding-left: 5px;
  padding-right: 5px;
  font-size: 12px !important;
}

.p-medical_management .datepick .input-group-addon {
  margin: 0;
}

.p-medical_management .datepick span.glyphicon {
  margin: 0;
}

.date-fix .react-datepicker__day--selected {
  background: #02a3af !important;
  color: #fff;
}

.date-fix .react-datepicker-popper {
  -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

.date-fix .react-datepicker__triangle::before,
.date-fix .react-datepicker__triangle::after {
  background: none !important;
  border: none !important;
}

.date-fix .react-datepicker__input-time-container {
  margin-left: 0;
  padding-left: 15px;
}

.space-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.space-between .inputGroup {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 31%;
  flex: 0 0 31%;
}

.groupBtn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  position: relative;
  z-index: 9;
}

.groupBtn a {
  margin: 0 20px;
  padding: 0.375rem 1.75rem;
}

.modelViewer {
  background: #fff;
}

.modelViewer h4 {
  margin-bottom: 0;
}

.modelViewer .modal-content {
  border: none;
}

.modelViewer .groupBtn {
  margin-top: -10px;
}

.modal-bodyViewer {
  max-height: 83vh;
}

@media (max-width: 768px) {
  .medicalConfirm.card-body {
    padding: 15px;
  }
}

.list-galerry .loadmore {
  display: none;
}

.infoConfirmation .blockHead {
  background: #f2f5f5;
  border-radius: 4px;
  padding: 20px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 60px;
}

@media (max-width: 1199px) {
  .infoConfirmation .blockHead {
    margin-bottom: 40px;
  }
}

.infoConfirmation .blockHead-left {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 65%;
  flex: 0 0 65%;
  padding-right: 3%;
}

.infoConfirmation .blockHead-left dl {
  border-bottom: 1px solid #fff;
  font-size: 16px;
  padding: 15px 0;
  margin-bottom: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 1199px) {
  .infoConfirmation .blockHead-left dl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .infoConfirmation .blockHead-left dl {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.infoConfirmation .blockHead-left dl:last-child {
  border-bottom: 0;
}

.infoConfirmation .blockHead-left dl dt {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 130px;
  flex: 0 0 130px;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .infoConfirmation .blockHead-left dl dt {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 110px;
    flex: 0 0 110px;
  }
}

.infoConfirmation .blockHead-left dl dd {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin-bottom: 0;
}

.infoConfirmation .blockHead-left .txtMedical span {
  background: #4e5151 url(./assets/common_img/ico-user.png) no-repeat left 5px center;
  padding: 3px 15px 3px 40px;
  background-size: 24px;
  border-radius: 30px;
  color: #fff;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin: 3px 0;
  margin-right: 10px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.infoConfirmation .blockHead-information {
  background: url(./assets/common_img/ico-profile.png) no-repeat left -20px center;
  padding-left: 180px;
}

@media (max-width: 768px) {
  .infoConfirmation .blockHead-information {
    background-size: 120px;
    background-position: left 0 center;
    padding-left: 130px;
    padding-right: 0;
  }
}

@media (max-width: 480px) {
  .infoConfirmation .blockHead-information {
    background-size: 0;
    background-position: left 0 center;
    padding-left: 0;
    padding-right: 0;
  }
}

.infoConfirmation .blockHead-right {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 35%;
  flex: 0 0 35%;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media (max-width: 1199px) {
  .infoConfirmation .blockHead-right {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}

@media (max-width: 768px) {
  .infoConfirmation .blockHead-right {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.infoConfirmation .blockHead .pdffile {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 49%;
  flex: 0 0 49%;
}

@media (max-width: 1199px) {
  .infoConfirmation .blockHead .pdffile {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 45%;
    flex: 0 0 45%;
  }
}

@media (max-width: 768px) {
  .infoConfirmation .blockHead .pdffile {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}

.infoConfirmation .blockHead .pdffile a {
  display: block;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
}

.infoConfirmation .blockHead .pdffile span {
  padding-left: 0;
}

.infoConfirmation .blockHead .nodeTxt {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 0;
  margin-top: auto;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 49%;
  flex: 0 0 49%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 1199px) {
  .infoConfirmation .blockHead .nodeTxt {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 53%;
    flex: 0 0 53%;
    margin-top: 0;
  }
}

@media (max-width: 768px) {
  .infoConfirmation .blockHead .nodeTxt {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    margin-top: 20px;
  }
}

.infoConfirmation .blockHead .nodeTxt .txtNotice {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

@media (max-width: 1199px) {
  .infoConfirmation .blockHead .nodeTxt .txtNotice {
    width: calc(100% - 102px);
  }
}

.infoConfirmation .blockHead .nodeTxt .icon {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 72px;
  flex: 0 0 72px;
  margin-left: 30px;
}

.infoConfirmation .txtNotice {
  background: #8fd274;
  border-radius: 10px;
  position: relative;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 15px;
  padding-right: 1px;
  max-height: 140px;
}

.infoConfirmation .txtNotice .inner {
  overflow: auto;
  max-height: 110px;
  padding-right: 5px;
}

.infoConfirmation .txtNotice .inner::-webkit-scrollbar {
  width: 5px;
}

.infoConfirmation .txtNotice .inner::-webkit-scrollbar-track {
  background: #41722d;
  border-radius: 3px;
}

.infoConfirmation .txtNotice .inner::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 3px;
}

.infoConfirmation .txtNotice .inner::-webkit-scrollbar:vertical {
  height: 0;
}

.infoConfirmation .txtNotice .inner::-webkit-scrollbar-track:vertical {
  height: 0;
}

.infoConfirmation .txtNotice .inner::-webkit-scrollbar-thumb:vertical {
  height: 0;
}

.infoConfirmation .txtNotice p {
  margin-bottom: 0;
  font-size: 14px;
}

.infoConfirmation .txtNotice::after {
  position: absolute;
  content: "";
  right: -12px;
  top: 50%;
  margin-top: -6px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 0 6px 12px;
  border-color: transparent transparent transparent #8fd274;
}

.infoConfirmation .blockImage {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 60px;
}

@media (max-width: 1199px) {
  .infoConfirmation .blockImage {
    margin-bottom: 40px;
  }
}

.infoConfirmation .blockImage-left {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 65%;
  flex: 0 0 65%;
  padding-right: 20px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

@media (max-width: 1199px) {
  .infoConfirmation .blockImage-left {
    padding-right: 0;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}

.infoConfirmation .blockImage-left .title {
  background: #e6f8f8;
  border-radius: 20px;
  padding: 10px 20px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 30px;
}

@media (max-width: 1199px) {
  .infoConfirmation .blockImage-left .title {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.infoConfirmation .blockImage-left p {
  margin-bottom: 0;
  font-weight: bold;
}

.infoConfirmation .blockImage-left .txtSmoking {
  background: #e86161 url(./assets/common_img/ico-smoke.png) no-repeat left 15px center;
  padding: 5px 20px;
  padding-left: 50px;
  border-radius: 30px;
  color: #fff;
  font-weight: normal;
}

.infoConfirmation .blockImage-right {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 35%;
  flex: 0 0 35%;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  background: #e6f8f8;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 1199px) {
  .infoConfirmation .blockImage-right {
    margin-top: 25px;
    padding: 20px 0;
  }
}

.infoConfirmation .blockImage-right .TeethSVG {
  position: relative;
}

.infoConfirmation .blockImage-right .TeethSVG p {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #4e5151;
  border-radius: 20px;
  padding: 5px 20px;
  color: #fff;
  margin-bottom: 0;
  font-size: 14px;
}

.infoConfirmation .listImages {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  margin-bottom: 30px;
}

.infoConfirmation .listImages .list-galerry label {
  min-width: inherit;
}

.infoConfirmation .listImages .row {
  padding: 0;
  margin: 0;
  max-width: 100%;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.infoConfirmation .listImages .img-wrapper {
  padding: 0;
  width: 23.5%;
  margin-right: 2%;
}

.infoConfirmation .listImages .img-wrapper:nth-child(4n) {
  margin-right: 0;
}

@media (max-width: 991px) {
  .infoConfirmation .listImages .img-wrapper {
    margin-top: 0;
    margin-bottom: 20px;
  }
  .infoConfirmation .listImages .img-wrapper:nth-of-type(n + 4) {
    margin-top: 0;
  }
}

.infoConfirmation .listImages .img-wrapper figure {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
}

.infoConfirmation .listImages .img-wrapper figure .images {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.infoConfirmation .listImages .img-wrapper figure img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.infoConfirmation .listImages .img-wrapper figure video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.infoConfirmation .listImages .loadmore {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #f2f5f5;
  border-radius: 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 36px;
  margin-top: 20px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.infoConfirmation .listImages .loadmore span {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.infoConfirmation .listImages .loadmore:hover {
  opacity: 0.7;
}

.infoConfirmation .listImages.hideImages .img-wrapper {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.infoConfirmation .listImages.hideImages .img-wrapper:nth-child(n + 13) {
  display: none;
}

.infoConfirmation .listImages.showImages .loadmore span {
  -webkit-transform: scaleY(-1);
  -ms-transform: scaleY(-1);
  transform: scaleY(-1);
}

.infoConfirmation .blockMedicalTable {
  margin-bottom: 60px;
}

@media (max-width: 1199px) {
  .infoConfirmation .blockMedicalTable {
    margin-bottom: 40px;
  }
}

.infoConfirmation .blockMedicalTable td.background-blue {
  color: #02a3af;
}

.infoConfirmation .blockData3D {
  background: #f2f5f5;
  border-radius: 20px;
  padding: 30px 35px;
  margin-bottom: 30px;
}

@media (max-width: 1199px) {
  .infoConfirmation .blockData3D {
    padding: 25px 20px;
  }
}

.infoConfirmation .blockData3D dl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
  flex-wrap: wrap;
}

.infoConfirmation .blockData3D dl dt {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 130px;
  flex: 0 0 130px;
  margin: 0;
}

@media (max-width: 1199px) {
  .infoConfirmation .blockData3D dl dt {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 90px;
    flex: 0 0 90px;
  }
}

.infoConfirmation .blockData3D dl dd {
  margin: 0;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.infoConfirmation .blockData3D.blockOrthodontist {
  padding: 0;
}

.infoConfirmation .blockData3D.blockOrthodontist dt {
  background: #4e5151;
  color: #fff;
  height: auto;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.infoConfirmation .blockData3D.blockOrthodontist dd {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding: 30px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 80%;
  flex: 0 0 80%;
}

.infoConfirmation .blockData3D.blockOrthodontist dd .blockNotice {
  margin-bottom: 0;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 59%;
  flex: 0 0 59%;
}

.infoConfirmation .blockData3D.blockOrthodontist dd .btnpdf {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 38%;
  flex: 0 0 38%;
}

.infoConfirmation .blockData3D.blockOrthodontist dd .btnpdf .btn-pdf {
  padding: 20px;
  display: block;
}

.infoConfirmation .blockData3D.blockOrthodontist dd .txtNotice {
  margin-bottom: 0;
  padding-right: 10px;
  max-height: 130px;
}

.infoConfirmation .blockData3D.blockOrthodontist dd .txtNotice::after {
  border: none;
}

.infoConfirmation .blockNotice {
  margin-bottom: 30px;
}

.infoConfirmation .blockNotice .txtNotice {
  border-radius: 20px;
  white-space: pre-wrap;
}

.infoConfirmation .blockNotice .txtNotice::after {
  border-width: 12px 6px 0 6px;
  border-color: #8fd274 transparent transparent transparent;
  top: inherit;
  left: 50%;
  margin-left: -12px;
  bottom: -12px;
}

@media (max-width: 768px) {
  .infoConfirmation .list-galerry {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.infoConfirmation .list-galerry-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  border: 1px solid #02a3af;
  border-radius: 35px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  padding: 7px 20px;
  margin-right: 15px;
  cursor: pointer;
}

.infoConfirmation .list-galerry-item:last-child {
  margin-right: 0;
}

@media (max-width: 768px) {
  .infoConfirmation .list-galerry-item {
    margin: 5px 0;
    margin-right: 10px;
  }
}

.infoConfirmation .list-galerry-item figure {
  height: auto;
  margin: 0;
  margin-right: 10px;
}

.infoConfirmation .list-galerry-item figure img {
  height: 24px;
}

.infoConfirmation .list-galerry-item label {
  margin: 0;
  padding: 0;
  border: none;
  min-width: 120px;
  max-width: 120px;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.infoConfirmation .list-galerry-item:hover {
  background: #02a3af;
}

.infoConfirmation .list-galerry-item:hover label {
  background: none;
  color: #fff;
}

@media (max-width: 639px) {
  .navbar {
    border-bottom: 0;
  }
}

#redirectLink .modal-dialog {
  margin-top: 15vh;
}

#redirectLink .modal-content {
  padding: 20px;
}

#redirectLink .modal-content .modal-header {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  border-bottom: none;
  padding: 0;
}

#redirectLink .modal-footer {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

#redirectLink h4 {
  text-align: center;
}

#redirectLink a.btn {
  font-size: 16px;
  padding: 0.5rem 10px;
  min-width: 300px;
  font-weight: normal;
  margin-bottom: 10px;
}

.dataTable-table td .fcc a.disable {
  pointer-events: none;
}

.p-analysis_management .analysis-body .tab-box-item {
  padding-bottom: 40px;
}

.p-analysis_management .analysis-body #review-tab1 .comprehensive-box {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: calc(33.33% - 20px) calc(33.33% - 20px) calc(33.33% - 20px);
  grid-template-columns: calc(33.33% - 20px) calc(33.33% - 20px) calc(33.33% - 20px);
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-column-gap: 20px;
  -moz-column-gap: 20px;
  grid-column-gap: 20px;
  column-gap: 20px;
}

.p-analysis_management .analysis-body .comprehensive-box--ins {
  padding: 10px;
}

.p-analysis_management .analysis-body .btn-pdf {
  margin-bottom: 10px;
}

.ModalImages .modal-dialog {
  max-width: 100%;
  min-height: 100%;
  margin: 0 !important;
  display: flex;
}
.ModalImages .modal-content {
  width: 100%;
  min-height: 100%;
  background-color: #241f1a;
}
.ModalImages .modal-body {
  flex: unset;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.ModalImages .image-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
}
.ModalImages .image-navigation {
  position: fixed;
  top: 4rem;
  right: 4rem;
  background-color: white;
  padding: 1rem 0;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  z-index: 1;
}
.ModalImages .image-navigation .nav-item {
  padding: 0 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  user-select: none;
}
.ModalImages .image-navigation .nav-item img {
  background-color: grey;
}
.ModalImages .image-navigation .nav-item p {
  margin-bottom: 0;
}
.ModalImages .image-container canvas {
  max-width: 100%;
  max-height: 100%;
}

label.cursor-pointer {
  cursor: pointer;
}

#tab1 .graph-style3,
#review-tab1 .graph-style3 {
  display: none;
}

#CSVOption .modal-content {
  border-radius: 20px;
  border: none;
  margin-top: 15vh;
  padding: 10px;
}

#CSVOption .modal-header {
  padding: 0;
  border-bottom: 0;
}

#CSVOption .modal-content {
  min-width: 500px;
  text-align: center;
}

#CSVOption .modal-footer {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

#CSVOption .modal-footer a {
  font-size: 16px;
  padding: 0.6rem 10px;
  min-width: 300px;
  font-weight: normal;
  margin-bottom: 10px;
}

.medical_management-fix .list-galerry figure {
  position: relative;
  width: 100%;
}

.medical_management-fix .list-galerry figure .images {
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.medical_management-fix .list-galerry figure img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.medical_management-fix .list-galerry figure video {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

@media (max-width: 1199px) {
  .medical_management-fix .table:not(.dataTable-table) {
    table-layout: auto;
  }
  .medical_management-fix .table:not(.dataTable-table) td:nth-child(2) {
    max-width: 85%;
  }
  .medical_management-fix .fixscroll .table {
    table-layout: fixed;
  }
}

.card .card-body {
  overflow: hidden;
}

.dataTable-table > thead > tr > td {
  min-width: 100px;
}

.medical_management-fix .dataTable-table > thead > tr > td {
  min-width: inherit;
}

.p-notice_operator .card-body .card {
  margin-bottom: 30px;
}

.p-notice_operator .card-body .card:last-child {
  margin-bottom: 0;
}

.p-notice_operator .card-body .card-noimg {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.p-notice_operator .card-body .card-noimg .flexbox {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}

.p-notice_operator .card-body .card-noimg .flexbox .text,
.p-notice_operator .card-body .card-noimg .flexbox .title,
.p-notice_operator .card-body .card-noimg .flexbox .linewrap {
  width: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}

@media (max-width: 1199px) {
  .p-notice_operator .card-body .card-noimg .text {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}

.page-login .container-fluid {
  padding: 0;
}

@media (max-width: 639px) {
  .page-login .container-fluid {
    padding: 10px;
  }
}

#imageType h4 {
  font-size: 1rem;
}

.medical-detail-date-picker .react-datepicker-wrapper {
  width: 100%;
}
.medical-detail-date-picker .react-datepicker-wrapper input {
  background: white !important;
  border: 1px solid #cccccc !important;
  border-radius: 4px;
}

.image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.image-container .images {
  width: 100%;
  height: 100%;
}
.image-container .select-box {
  width: 30px !important;
  height: 30px !important;
  position: absolute;
  top: 5px;
  left: 5px;
}

.infoConfirmation .partner-company {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}
